import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../Particle";
import ExperienceCard from "./ExperienceCard";

function About() {
  const listOfCompanies = [
    {
      "position": "Full Stack Developer",
      "roles": [
        "Actively architected and led end-to-end development of Trademanza's React web interface for 100,000+ users. Orchestrated clean architecture patterns, proficient in React Hooks, data libraries, and third-party API integration.",
        "For the React website, built reusable components for stock-card, news-card, and leaderboard components and published them in the npm registry and integrated 3rd party APIs.",
        "Pioneered Micro Frontend Architecture, crafted npm packages, integrated Ant Design, and stayed updated on ES advancements.",
        "Implemented CI/CD pipeline using GitHub actions, which automatically ran tests while building, and notified about build status in slack channels, leading to significant ease in deployment.",
        "Utilized AWS S3 and Cloudfront for the website deployment and API Gateway service to create an API gateway for the microservices hosted on EC2 instances in a secure Agile environment.",
        "Implemented RESTful APIs using Node.js & Express.js for microservice architecture to build authentication, games & polling services, and wallet transaction management APIs."
      ]
    },
    {
      "position": "Full Stack Developer",
      "companyName": "Quizizz | Bangalore, India",
      "roles": [
        "Leveraged an in-depth understanding of React.js core principles and demonstrated expertise in popular workflows like Redux. Architected and developed reusable React components for question-editing, option-editing, and multimedia uploads, catering to an extensive user base of 10M+.",
        "Seamlessly migrated JavaScript-based quiz-related components to TypeScript, enhancing code maintainability and reliability.",
        "Spearheaded the integration of classroom functionality into a React Native Quizizz app, aligning it with the LMS platform for educators. Leveraged Micro Frontend Architecture, crafting npm packages. Exhibited advanced proficiency in React Hooks, embraced modern ECMAScript specifications.",
        "Revamped existing RESTful APIs built with Node.js & Express.js backend for microservice architecture, created APIs to update customer-issue tickets to better segregate customer queries with Zendesk and webhook for the customer support team.",
        "Implemented integration end-to-end tests for microservice APIs using Jest, including comprehensive testing of 3rd party API resources.",
        "For the CI/CD pipeline built using github actions, configured the .yaml file to fetch secrets from Github secrets which helped in handling sensitive information better.",
        "Co-deployed and co-managed project’s cloud-based code on AWS in a collaborative environment with skilled developers for a user base of over 10M users, ensuring scalability and high availability."
      ]
    },
    {
      "position": "Full Stack Developer",
      "companyName": "Antizero Technologies",
      "roles": [
        "Using ReactJS created modular components, managed props and state, and implemented conditional rendering to craft an engaging web interface for Jumpin",
        "Used react-router for smooth navigation and redux for global state management.",
        "Employed UI debugging tools such as React DevTools and browser developer tools to efficiently identify and resolve issues, enhancing the overall user experience.",
        "To build RESTful login, content upload, and dynamic user feed APIs, used Node.js & Express.js as backend and followed Domain Driven Design. Used Firebase for real-time messaging and push notifications."]
    },
    {
      "position": "Android Developer",
      "companyName": "Google Summer Of Code | Metabrainz | California, USA",
      "roles": [
        "Enhanced legacy codebase, identifying improvements for stability. Provided comprehensive reports with recommended fixes, highlighting my grasp of effective client/server interactions.",
        "Modernized UI using Java Native Interface, and C++ TagLib integration for metadata manipulation. Transformed UI with XML, Material Design, Fragments, and RecyclerViews, showcasing architectural skills.",
        "Proactively resolved bugs, introduced features in Java, and reviewed code thoroughly. Leveraged Kotlin for innovative solutions, and documented enhancements. Demonstrated commitment to optimized user experience, collaborating via GitHub."
      ]
    },
    {
      "position": "Full Stack Developer - Intern",
      "companyName": "Skylark Labs - Intern",
      "roles": [
          "Utilized ReactJS to build an API management console to monitor and track API usage.",
          "Developed customizable billing reports with React's state management library Redux. Users generate detailed reports based on API usage, demonstrating proficiency in data presentation and manipulation.",
          "Implemented real-time tracking using React and WebSocket integration. Users can monitor API usage instantaneously, showcasing dynamic and responsive interface skills.",
          "Created advanced user role management using React, enabling administrators to control access. Expertise in complex state handling and security measures is evident.",
          "Utilized Git Large File Storage extension with existing GitHub Repository to store large ML models.",
          "Implemented API end-points to allow end-users to access machine learning models using API."
        ]  
    }
  ]
  return (
    <Container fluid className="about-section">
      <Particle />
      {
        listOfCompanies.map((company) => 
          <ExperienceCard companyDetail={company} />
        )
      }
    </Container>
  );
}

export default About;
