import axios from "axios";
import { baseUrl } from "./configuration";

const putContact = async (email, username, title, message) => {
    const url = baseUrl+'/contact/add'
    const data = {
        "email": email,
        "username": username,
        "msgSubject": title,
        "msgBody": message
      };
    
    const response = await axios.put(url, data);
    return response;
}

const APIs = {
    putContact
}

export default APIs;