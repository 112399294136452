import React from 'react';

const LoadingSpinner = () => {
  return (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      );
};

export default LoadingSpinner;
