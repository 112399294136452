import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../Assets/sd.json';

const LottieAnimation = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
    };

    return <Lottie options={defaultOptions} />;
};

export default LottieAnimation;