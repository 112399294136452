import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h4>Few Words</h4>
            <h1 style={{ fontSize: "2.6em" }}>
               <span className="purple"> ABOUT ME </span> 
            </h1>
            <p className="home-about-body">
            With a perfect balance between frontend and backend expertise, I orchestrate software symphonies from inception to completion
              <br />
              <br />From implementing  
              <i>
                <b className="purple"> Scalable Microservices </b>
              </i>
              to deploying
              <i>
                <b className="purple"> Large Scale Solutions </b>
              </i> 
              I thrive on pushing technological boundaries. My love for elegant code is matched only by my commitment to delivering practical solutions.
              <br />
              <br />
              As an 
              <i>
                <b className="purple"> AWS Certified Developer Associate, </b>
              </i> 
              I leverage cloud technologies to create robust and reliable applications.
              <br />
              <br />
              Let's embark on an exciting journey of innovation together. Join me in exploring the limitless possibilities of technology
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
