import { Container, Col } from "react-bootstrap";


const ExperienceCard = (props) => {
    const companyDetail = props.companyDetail;
    return (
        <Container fluid className="experience-section" id="about">
                <Col md={8} className="home-about-description">
                    <h3 style={{ fontSize: "1.6em" }}>
                        <span className="purple"> {companyDetail.position} </span> 
                        <br/>
                        {companyDetail.companyName}
                    </h3>
                    <p className="home-about-body">
                        <ul>
                            {companyDetail.roles.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </p>
                </Col>
        </Container>
    );

}

export default ExperienceCard;