import APIs from "../../Network/requests"
import { useState } from "react";
import LoadingSpinner from "../LoadingSpinner";

const Contact = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // form submission logic 
        setIsLoading(true);
        APIs.putContact(email, name, title, message).then((response)=>{
            setIsLoading(false);
            if(response.status===200){
                alert("Thank you! I've got your message")
            }else{
                alert("Uh-oh! Your message could not be delivered");
            }
        })
        // Reset the form fields
        setEmail('');
        setName('');
        setTitle('');
        setMessage('');
    };

    const loadingOrButton = isLoading ?  <button type="submit"><LoadingSpinner/></button> : <button type="submit">Submit</button>;

    return (
        <div className="contact-section">
            <div className="contact-form-container">
            <div className="contact-form-left">
                <h4>Let's <span className="purple">connect</span>  and create amazing projects together! 
                <br/> 
                Drop a <span className="purple">message</span> below</h4>
            </div>
            <form onSubmit={handleSubmit} className="contact-form-right">
                <div className="form-row">
                    <label htmlFor="email">Email </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-row">
                    <label htmlFor="name">Name </label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-row">
                    <label htmlFor="title">Subject </label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="form-row">
                    <label htmlFor="message">Message </label>
                    <textarea
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    ></textarea>
                </div>
                {loadingOrButton}
            </form>
        </div>
        </div>
    );
}

export default Contact;