import React from "react";
import { Container, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Footer() {
  return (
    <Container fluid className="footer">
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
          <li className="social-icons">
                <a
                  href="https://github.com/SomalRudra"
                  target="_blank"
                  rel="noreferrer"
                  className="no-preference"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/SomalChakrabrty"
                  target="_blank"
                  rel="noreferrer"
                  className="no-preference"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/somalchakraborty/"
                  target="_blank"
                  rel="noreferrer"
                  className="no-preference"
                >
                  <FaLinkedinIn />
                </a>
              </li>
          </ul>
          <h4 style={{ fontSize: "0.2em"}}>Template by Soumyajit Behera | Source Code Changes & Modifications by Somal Chakraborty</h4>
        </Col>
    </Container>
  );
}

export default Footer;
